import { callSequence, setStoreWrapper } from '../StoreManager'
import { getPostAuthor } from '../postUtils'
import { checkMySubscription } from '../subscriptionUtil'

const LOAD_MORE_GAP = 5

export function getProfileDatingCardID(postId){
  return 'profilePost' + postId
}

export function scrollToNextPost(postId) {
  const currentElem = document.getElementById(getProfileDatingCardID(postId))
  if (!currentElem || !currentElem.parentNode || !currentElem.parentNode.children) {
    return
  }
  const children = currentElem.parentNode.children
  let element
  for (let i = 0; i < children.length; i++) {
    element = children[i]
    if (element === currentElem) {
      const nextElement = children[i + 1]
      if (nextElement) {
        if (nextElement.id === 'profilePostundefined' || nextElement.id === 'emptyILike') {
          setStoreWrapper('currentPostId', -1)
          setStoreWrapper('currentUser', null)
          setTimeout(() => {
            nextElement.scrollIntoView({ behavior: 'smooth' })
          }, 250)
          return
        }
        const postId = elemIdToPostId(nextElement.id)
        if (!checkMySubscription(getPostAuthor(postId))) {
          setTimeout(() => {
            nextElement.scrollIntoView({ behavior: 'smooth' })
          }, 250)
          //если осталось не отсмотренными 3 профиля, то грузим следующую страницу
          if (i + LOAD_MORE_GAP > children.length) {
            callSequence('posts.getMorePosts')
          }
          setStoreWrapper('currentPostId', postId)
          setStoreWrapper('currentUser', getPostAuthor(postId))
          // setStoreWrapper('scrollNext', null)
          return
        }
      }
    }
  }
  // setStoreWrapper('scrollNext', null)
}

export function scrollToPost(postId) {
  const currentElem = document.getElementById(getProfileDatingCardID(postId))
  if (currentElem) {
    currentElem.scrollIntoView()
  }
}

function elemIdToPostId(elemId) {
  return Number(elemId.replace('profilePost', ''))
}
