import { getSettings } from './settingsUtil'
import { getAppId, getGen, isTWA } from './twa'
import { CHAT_ALL } from './chat/chatUtil'
import { getState } from './StoreManager'
import { imFemale, imModel } from './my'
import { IOS_DATING_APP_ID, isIOSPWA } from './api/PWAUtil'

const defaultConfigTWA = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity: false
}

const defaultConfigWEB = {
  privates_disabled: false,
  chat_mode: CHAT_ALL,
  custom_filters: false,
  payout_disabled: false,
  diversity: false
}

export function getAppConfig() {
  return getSettings('mode_' + getAppId() + '_' + getGen()) || (isTWA() ? defaultConfigTWA : defaultConfigWEB)
}

export function is12Config() {
  const config = getAppConfig()
  return config.r
}

export function isNoDatingInConfig() {
  // return true
  const config = getAppConfig()
  return config.no_dating
}

export function isCallDisableInConfig() {
  const config = getAppConfig()
  return config.privates_disabled
}

export function isKickMode() {
  const config = getAppConfig()
  return config.privates_kick
}

export function isDatingFilterDisable() {
  const config = getAppConfig()
  return config.custom_filters
}

export function isPayoutDisable(onlyConfig) {
  const config = getAppConfig()
  const dollars = getState('auth.usdBalance')
  if (config.payout_disabled) {
    if (dollars && imModel()) {
      return true
    }
    return config.payout_disabled
  }

  //для IOS вывод скрыт всегда, не смотря на пол
  if (isIOSPWA() && !dollars) {
    return true
  }

  //если я мужик без долларов то кнопки тоже нет
  if (!imFemale() && !dollars && !onlyConfig) {
    return true
  }

  return false
}

export function isDiversity() {
  const config = getAppConfig()
  return config.diversity
}

export function isDatingApp() {
  // return true
  return getAppId() === IOS_DATING_APP_ID
}

